.anglelogo {
    img{ 
    width: 100px;
    margin-left: 10px;
}}

.modal-header .btn-close {
    margin: -0.4rem -0.7rem -0.4rem 0 !important;
}
.modal-title {
    margin: auto;
}

.TracksLoader {
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 74vh; 
    .spinner-border{
        width: 50px;
        height: 50px;
    }
  }



button.outline-primary-btn {
    border: 2px solid #0d89ef; // don't change color 
    border-radius: 10px;
    font-weight: 500;
}